<template>
  <div class="preRegistration">
    <div class="preHeader">
      <img :src="schoolInfo.logoUrl" alt="">
    </div>
    <div class="preMain" ref="preMain">
      <div class="preMainBG"></div>
      <div class="preMainContent">
        <div :class="['preMainHead', { 'showMenu': showMenu }]">
          <div class="center" v-if="$route.query.serviceType">
            <div v-if="stationList[0]">{{ stationList[0].name }}</div>
            <div>招生预报名</div>
          </div>
          <template v-else>
            <div class="preMainHeadL" v-if="!showMenu">招生预报名</div>
            <van-dropdown-menu active-color="#1A7EE2" class="preMainHeadL">
              <van-dropdown-item @open="handleMenu" @close="handleMenu">
                <template #title>
                  <span class="stationAlias">{{ preSetInfo && preSetInfo.stationAlias ? preSetInfo.stationAlias : '教学点'
                  }}信息</span><img src="@/assets/img/downIcon.png" alt="">
                </template>
                <template #default>
                  <div class="menuItem" v-for="item in stationList" :key="item.id">
                    <div class="tips"></div>
                    <div class="menuItemMain" v-if="item">
                      <div class="title">{{ item.name }}</div>
                      <div :class="['phone', { 'flex': !item.aidPhone }]">
                        <div class="pTitle">招生联系电话：</div>
                        <div class="pItem" v-if="item.aidPhone">{{ item.aidPhone || '暂无' }}</div>
                        <span v-if="!item.aidPhone">暂无</span>
                      </div>
                      <div :class="['address', { 'flex': !item.address }]" v-if="item.address">
                        <div class="aTitle">地址：</div>
                        <div class="aItem" v-if="item.address">{{ item.address }}</div>
                        <span v-else>暂无</span>
                      </div>
                    </div>
                  </div>
                </template>
              </van-dropdown-item>
            </van-dropdown-menu>
          </template>
        </div>
        <div class="preContentBox" v-if="!showMaterials">
          <div class="preContentTime" v-if="preSetInfo && preSetInfo.startTime && preSetInfo.endTime">
            <span>报名时间：</span><span>{{ $way.timestampReturnDate(preSetInfo.startTime, 'timeYMDHM') }}-{{
              $way.timestampReturnDate(preSetInfo.endTime, 'timeYMDHM') }}</span>
          </div>
          <div
            :class="['preContentTime', { 'noline': !(preSetInfo && preSetInfo.dataList && preSetInfo.dataList.length > 0) }]"
            v-if="preSetInfo && preSetInfo.topPrompt">
            <span v-html="preSetInfo.topPrompt"></span>
          </div>
          <div class="preContentHead" v-if="preSetInfo && preSetInfo.dataList && preSetInfo.dataList.length > 0">
            <div :class="['preHeadItem', 'onStep']" @click="changeStep(1)">
              <div class="num">1</div>
              <span>填写报名信息</span>
            </div>
            <div class="preHeadLine"></div>
            <div :class="['preHeadItem', { 'onStep': step == 2 }]" @click="changeStep(2)"
              v-if="preSetInfo && preSetInfo.dataList && preSetInfo.dataList.length > 0">
              <div class="num">2</div>
              <span>上传报名资料</span>
            </div>
          </div>
          <div class="preContent">
            <!-- 填写内容 -->
            <van-form @failed="onFailed" @submit="onFiedSubmit">
              <van-cell-group inset>
                <template v-if="step == 1">
                  <template v-if="preSetInfo && preSetInfo.infoList">
                    <template v-for="(item, index) in preSetInfo.infoList">
                      <template v-if="item.fieldName">
                        <!-- 姓名 -->
                        <van-field v-if="item.fieldName == 'name'" :key="index" class="line" v-model="formLine.name"
                          name="name" label="姓名" required :placeholder="item.promptContent?item.promptContent:'请填写姓名'" :rules="rules.name" />
                        <!-- 身份证号 -->
                        <van-field class="line" v-if="item.fieldName == 'cardId'" :key="index" v-model="formLine.cardId"
                          name="cardId" label="身份证号" :placeholder="item.promptContent?item.promptContent:'请填写身份证号'" required :rules="rules.cardId"
                          :formatter="(value) => value.toUpperCase()" @blur="onFieldBlur('cardId')" />
                        <!-- 户籍所在地 -->
                        <van-field v-if="item.fieldName == 'regionId'" :key="index" v-model="formLine.regionId" is-link
                          readonly
                          :disabled="preSetInfo.domicileSetVO.province && preSetInfo.domicileSetVO.city ? true : false"
                          name="area" label="户籍所在地" required>
                          <template #input>
                            <div id="fieldValue" @click="onClickArea(1)">
                              <span class="gray" v-if="preSetInfo && preSetInfo.domicileSetVO.province">{{
                                preSetInfo.domicileSetVO.province.name }}</span>
                              <span v-else>{{ formLine.provinceName || '选择省' }}</span>
                              <img src="@/assets/img/downIcon2.png" alt="">
                            </div>
                            <div id="fieldValue" @click="onClickArea(2)">
                              <span class="gray" v-if="preSetInfo && preSetInfo.domicileSetVO.city">{{
                                preSetInfo.domicileSetVO.city.name }}</span>
                              <span v-else>{{ formLine.cityName || '选择市区' }}</span>
                              <img src="@/assets/img/downIcon2.png" alt="">
                            </div>
                          </template>
                        </van-field>
                        <!-- 联系号码 -->
                        <van-field class="line phone" v-if="item.fieldName == 'phone'" :key="index"
                          v-model="formLine.phone" center clearable required label="联系号码" :placeholder="item.promptContent?item.promptContent:'请填写联系电话'"
                          :rules="rules.phone">
                          <template #button v-if="preSetInfo.codeDisplayFlag">
                            <van-button v-if="!sent" size="mini" class="sentCode" plain native-type="button"
                              @click="getSendCode">发送验证码</van-button>
                            <!-- <span >{{sentNum}}秒</span> -->
                            <van-count-down v-else ref="countDown" millisecond :time="60000" format="ss 秒"
                              @finish="handleFinish" />
                            <!-- <span><van-count-down :time="time" /></span> -->
                          </template>
                        </van-field>
                        <!-- 验证码 -->
                        <van-field class="line" v-if="item.fieldName == 'code'" :key="index" v-model="formLine.code"
                          label="填写验证码" required :placeholder="item.promptContent?item.promptContent:'请输入验证码'" />
                        <!-- 教学点 -->
                        <van-field v-if="!$route.query.serviceType && item.fieldName == 'stationId'" :key="index"
                          v-model="formLine.stationId" is-link readonly
                          :label="preSetInfo && preSetInfo.stationAlias ? preSetInfo.stationAlias : '报名教学点'"
                          @click="handleModel('station')" required>
                          <template #input>
                            <div id="fieldValue">
                              <span :class="item.value?'':'gray'">{{ formLine.stationName || (item.promptContent?item.promptContent:'请选择') }}</span>
                              <img src="@/assets/img/downIcon2.png" alt="">
                            </div>
                          </template>
                        </van-field>
                        <!-- 报名批次 -->
                        <van-field v-model="formLine.admissionBatch" v-if="item.fieldName == 'admissionBatch'"
                          name="admissionBatch" :key="index" is-link readonly label="报名批次" @click="handleModel('batch')"
                          :disabled="!formLine.stationId" required>
                          <template #input>
                            <div id="fieldValue">
                              <span :class="item.value?'':'gray'">{{ formLine.admissionBatch || (item.promptContent?item.promptContent:'请选择批次') }}</span>
                              <img src="@/assets/img/downIcon2.png" alt="">
                            </div>
                          </template>
                        </van-field>
                        <!-- 学历 -->
                        <van-field v-if="item.fieldName == 'education'" :key="index" name="education" label="当前学历"
                          :disabled="!formLine.stationId" @click="handleModel('education')" required>
                          <template #input>
                            <van-radio-group :disabled="!formLine.stationId" v-model="formLine.education"
                              direction="horizontal">
                              <van-radio class="bottom24" :name="4">初中</van-radio>
                              <van-radio class="bottom24" :name="1">高中（或同等学力）</van-radio>
                              <van-radio class="bottom24" :name="2">专科</van-radio>
                              <van-radio :name="3">本科（本科及以上）</van-radio>
                            </van-radio-group>
                          </template>
                        </van-field>
                        <!-- 层次 -->
                        <van-field v-if="item.fieldName == 'eduCategory' && formLine.admissionBatch" :key="index"
                          name="eduCategory" label="报名层次" required :disabled="!formLine.admissionBatch"
                          @click="handleModel('eduCategory')">
                          <template #input>
                            <van-radio-group v-model="formLine.eduCategory" :disabled="!formLine.admissionBatch"
                              direction="horizontal"
                              @change="getPreMajorList(formLine.admissionBatch, formLine.eduCategory)">
                              <van-radio :class="`${'bottom24-' + index}`" :name="item.id"
                                v-for="(item, index) in eduCategoryList" :key="item.id">{{ item.label }}</van-radio>
                            </van-radio-group>
                          </template>
                        </van-field>
                        <!-- 专业 -->
                        <van-field v-model="formLine.majorId" v-if="item.fieldName == 'majorId'" :key="index" is-link
                          readonly name="majorId" label="报名专业"
                          :disabled="!(formLine.eduCategory || formLine.eduCategory === 0)" @click="handleModel('major')"
                          required>
                          <template #input>
                            <div id="fieldValue">
                              <span :class="item.value?'':'gray'">{{ formLine.majorName || (item.promptContent?item.promptContent:'请选择专业') }}</span>
                              <img src="@/assets/img/downIcon2.png" alt="">
                            </div>
                          </template>
                        </van-field>
                      </template>
                      <!-- 自定义填写信息 -->
                      <template v-else>
                        <!-- 输入 -->
                        <van-field v-if="item.fillType == 1 && item.displayFlag == 1" class="line" :key="index"
                          v-model="item.value" :name="item.requiredFlag == 1 ? 'tips' : ''" :label="item.name"
                          :placeholder="item.promptContent?item.promptContent:'请输入' + item.name" :required="item.requiredFlag == 1 ? true : false"
                          :rules="item.requiredFlag == 1 ? rules.tips : null" @blur="onFieldBlur(item)" />
                        <!-- 选项 -->
                        <van-field v-if="item.fillType == 2 && item.displayFlag == 1" :key="index" v-model="item.value"
                          is-link readonly :name="'name' + index" :label="item.name"
                          :required="item.requiredFlag == 1 ? true : false"
                          @click="handleModel('diyItem' + index, item.optionItem,)">
                          <template #input>
                            <div id="fieldValue">
                              <span :class="item.value?'':'gray'">{{ item.value || (item.promptContent?item.promptContent:'请选择' + item.name) }}</span>
                              <img src="@/assets/img/downIcon2.png" alt="">
                            </div>
                          </template>
                        </van-field>
                      </template>
                    </template>
                  </template>
                </template>
                <template v-else>
                  <!-- 下一页 -->
                  <template v-if="preSetInfo && preSetInfo.dataList">
                    <template v-for="(item, index) in preSetInfo.dataList">
                      <div class="uploadItem" v-if="getLoadItem(item, index)" :key="index">
                        <div class="uploadHead">
                          <div><span v-if="item.requiredFlag == 1" class="red">*</span>{{ item.name }}<span
                              v-if="item.requiredFlag == 0">(选填)</span></div>
                          <div class="example" v-if="item.description || item.mustDescription"
                            @click="handleExampleClick(item, index)">
                            <img src="@/assets/img/tips.png" alt="" srcset="">查看样例
                          </div>
                        </div>
                        <div class="uploadMain">
                          <div class="uploadImg" v-for="(uploadItem, uploadIndex) in item.uploadSet" :key="uploadIndex">
                            <div class="uploadImgBox">
                              <div v-if="!uploadItem.imgUrl" class="addImg" @click="handleUploadClick(item, uploadIndex)">
                                <img class="add" src="@/assets/img/preAdd.png" alt="" srcset="">
                                <div class="bgImg"
                                  :style="[{ 'background': 'url(' + uploadItem.bgImg + ') no-repeat' || '' }]">
                                </div>
                              </div>
                              <template v-else>
                                <van-image fit="contain" :src="uploadItem.imgUrl"
                                  @click="handleUploadClick(item, uploadIndex)" />
                              </template>
                            </div>
                            <div class="text">{{ uploadItem.text }}</div>
                          </div>
                        </div>
                      </div>
                    </template>
                  </template>
                </template>
              </van-cell-group>
              <div :class="['btn', { 'center': step == 1 }]">
                <van-button v-if="preSetInfo && preSetInfo.dataList.length > 0"
                  :class="['btnItem', 'blueBtn',{ 'next': step == 2 }]" round block @click="changeStep(step == 1 ? 2 : 1)"
                  :native-type="step == 1 ? 'submit' : ''">
                  {{ step == 1 ? '下一步' : '上一步' }}
                </van-button>
                <van-button class="btnItem blueBtn" v-if="step == 2 || preSetInfo && preSetInfo.dataList.length == 0" round block
                  :native-type="preSetInfo && preSetInfo.dataList.length == 0 ? 'button' : 'button'"
                  @click="onFiedSubmit('submit')">
                  上传完成并提交
                </van-button>
              </div>
            </van-form>

          </div>
        </div>
        <!-- 报名信息查询 -->
        <div class="preContentBox" v-if="showQuery() == 1">
          <div class="preContentHead column">
            <div class="title">查看报名结果/补充材料</div>
            <div class="headMain">
              <img class="icon" src="@/assets/img/tip.png" alt="">
              <span>请输入您已报名的身份证号码以及对应填写的联系电话</span>
            </div>
          </div>
          <div class="preContent">
            <!-- 填写内容 -->
            <van-form @submit="onSubmitQuery">
              <van-cell-group inset>
                <!-- 身份证号 -->
                <van-field class="line" v-model="formLine.cardId" name="cardId" label="身份证号" placeholder="请填写身份证号"
                  required :rules="rules.cardId" :formatter="(value) => value.toUpperCase()"
                  @blur="onFieldBlur('cardId')" />
                <!-- 联系号码 -->
                <van-field class="line phone" v-model="formLine.phone" center clearable required label="联系号码"
                  placeholder="请填写联系电话" :rules="rules.phone">
                  <template #button>
                    <van-button v-if="!sent" size="mini" class="sentCode" plain native-type="button"
                      @click="getSendCode">发送验证码</van-button>
                    <!-- <span >{{sentNum}}秒</span> -->
                    <van-count-down v-else ref="countDown" millisecond :time="60000" format="ss 秒"
                      @finish="handleFinish" />
                    <!-- <span><van-count-down :time="time" /></span> -->
                  </template>
                </van-field>
                <!-- 验证码 -->
                <van-field class="line" v-model="formLine.code" label="填写验证码" required placeholder="请输入手机短信验证码" />
                <!-- 教学点 -->
                <van-field v-if="!$route.query.serviceType" v-model="formLine.stationId" is-link readonly
                  :label="preSetInfo && preSetInfo.stationAlias ? preSetInfo.stationAlias : '报名教学点'"
                  @click="handleModel('station')" required>
                  <template #input>
                    <div id="fieldValue">
                      <span>{{ formLine.stationName || '请选择' }}</span>
                      <img src="@/assets/img/downIcon2.png" alt="">
                    </div>
                  </template>
                </van-field>
                <div :class="['btn']">
                  <van-button class="btnItem blueBtn" block :native-type="'submit'">
                    确认</van-button>
                  <van-button class="btnItem" block :native-type="'button'" @click="backApply()">
                    返回报名页面
                  </van-button>
                </div>
              </van-cell-group>
            </van-form>
          </div>
        </div>
        <!-- 报名信息详情/补充材料上传 -->
        <div class="preContentBox preContentBoxAuto preContent2" v-if="showQuery() > 1">
          <div :class="['preContentItem', { 'preHead2': showQuery() == 2 }, { 'preHead3': showQuery() > 2 }]">
            <span
              :class="[{ 'yellow': showQuery() == 2 }, { 'blue': showQuery() == 3 }, { 'red': showQuery() == 4 }]">报名{{
                showQuery() == 2 ? '审核中' : showQuery() == 3 ? '通过' : '不通过' }}</span>
            <div>
              <van-field v-model="materialData.admissionBatch" class="queryBatch" name="admissionBatch" is-link readonly
                label="报名批次" @click="handleModel('queryBatch')">
                <template #input>
                  <div id="fieldValue">
                    <span>{{ materialData.admissionBatch || '' }}</span>
                    <img src="@/assets/img/downIcon2.png" alt="">
                  </div>
                </template>
              </van-field>
            </div>
          </div>
          <div class="preBoxMain" v-if="showQuery() == 3 || showQuery() == 4">
            <div class="preContentItem">
              <span>审核时间:</span>
              <span>{{ $way.timestampReturnDate(materialData.stuAuditInfoVO.auditTime, 'timeYMDHM') }}</span>
            </div>
            <div class="preContentItem" v-if="materialData && materialData.stuAuditInfoVO.artsSciences">
              <span>文理科属性:</span>
              <span>{{ materialData.stuAuditInfoVO.artsSciences==1?'理科':'文科' }}</span>
            </div>
            <div class="preContentItem" v-if="materialData && materialData.stuAuditInfoVO.auditOpinion">
              <span>审核意见:</span>
              <span>{{ materialData.stuAuditInfoVO.auditOpinion }}</span>
            </div>
            <van-button color="#1A7EE2" class="reEnroll" v-if="showQuery() == 4" block @click="onReEnroll()">重新报名
            </van-button>
          </div>
        </div>
        <div class="preContentBox preContentBoxAuto" v-if="showQuery() > 1">
          <div class="preHead" v-if="showAudit('add')">
            <img class="icon" v-if="materialData && showAudit() == 1" src="@/assets/img/tips.png" alt="">
            <img class="icon" v-else src="@/assets/img/warn2.png" alt="">
            <span v-if="materialData && showAudit() == 0">补充材料<span class="yellow">审核中</span> ，请耐心等待</span>
            <span v-else-if="materialData && showAudit() == 1">您的补充材料已<span class="blue">审核通过</span></span>
            <span v-else-if="materialData && showAudit() == 2">您的补充材料<span class="red">审核不通过</span> ，请重新上传材料</span>
            <span v-else>按照报名要求，通过后您仍需补充上传部分材料</span>
          </div>
          <van-tabs v-model="materialActive" type="card" title-class="tabBox" color="#1A7EE2">
            <van-tab title="报名信息" name="a">
              <div class="preContentItem" v-for="(item, index) in queryInfos" :key="index">
                <span>{{ item.label }}:</span>
                <div>
                  <template v-if="item.value == 'commentTime'">
                    {{ $way.timestampReturnDate(materialData.stuAuditInfoVO[item.value], 'timeYMDHM') }}
                  </template>
                  <!-- <template v-else-if="item.value=='eduCategory'">
                    {{ eduCategory[materialData.stuAuditInfoVO[item.value] || 0] }}
                  </template> -->
                  <template v-else>
                    {{ materialData.stuAuditInfoVO ? (materialData.stuAuditInfoVO[item.value] || '暂无') : '暂无' }}
                  </template>
                </div>
              </div>
              <template v-if="materialData && materialData.stuAuditInfoVO">
                <template v-for="(item, index) in materialData.stuAuditInfoVO.extendList">
                  <div class="preContentItem" :key="index" v-if="item.type == 1 && (item.value || item.value == 0)">
                    <span>{{ item.name }}:</span>
                    <div>{{ item.value }}</div>
                  </div>
                </template>
              </template>
            </van-tab>
            <van-tab title="助学信息" name="b" v-if="showQuery() == 3 && materialData && materialData.stuAssistanceVO">
              <div class="preContentItem preContentItemCenter" v-for="(item, index) in heplStuInfos" :key="index">
                <div class="preInfoLable">
                  <span>{{ item.label }}:</span>
                  <span class="red tips" v-if="item.tips">{{ item.tips }}</span>
                </div>
                <template v-if="item.value === 'assistiveCourses'">
                  <div class="textR">
                    {{ materialData.stuAssistanceVO.assistiveCourses.join(',') }}
                  </div>
                </template>
                <template v-else-if="item.value === 'studyAddress'">
                  <div class="infoBtn" @click.stop="onBtnOS('copy')">
                    复制链接
                  </div>
                </template>
                <template v-else-if="item.value === 'appCode'">
                  <div class="infoBtn" @click="onBtnOS('download')">
                    点击前往下载学习
                  </div>
                </template>
                <template v-else>
                  <div>
                    {{ materialData.stuAssistanceVO ? (materialData.stuAssistanceVO[item.value] || '暂无') : '暂无' }}
                  </div>
                </template>
              </div>
            </van-tab>
            <van-tab title="报名材料" name="c" v-if="showQuery('material')">
              <template v-if="materialData && materialData.stuAuditInfoVO">
                <template v-for="(item, index) in materialData.stuAuditInfoVO.extendList">
                  <div class="preContentItem preContentItemColumn" :key="index"
                    v-if="item.type == 2 && item.displayFlag == 1 && item.value">
                    <span class="preInfoLable">{{ item.name }}:</span>
                    <div>
                      <van-image fit="contain" class="infoImg" v-for="(imgItem, imgIdx) in item.values" :key="imgIdx"
                        :src="imgItem" @click="onPreview(item.values, 'arr')" />
                    </div>
                  </div>
                </template>
              </template>
            </van-tab>
            <van-tab title="补充材料" name="d" v-if="showAudit('add')">
              <template v-if="materialData && materialData.stuAuditAddInfoVO">
                <div class="preContentItem" v-if="materialData && materialData.stuAuditAddInfoVO">
                  <span>审核状态:</span>
                  <span
                    :class="[{ 'yellow': showAudit() == 0 }, { 'blue': showAudit() == 1 }, { 'red': showAudit() == 2 }]">{{
                      showAudit() == 1 ? '审核通过' : showAudit() == 2 ? '审核不通过' : '审核中'
                    }}</span>
                </div>
                <div class="preContentItem" v-if="showAudit() !== 0">
                  <span>审核时间:</span>
                  <span>{{ $way.timestampReturnDate(materialData.stuAuditAddInfoVO.addAuditTime, 'timeYMDHM') }}</span>
                </div>
                <div class="preContentItem"
                  v-if="materialData && materialData.stuAuditAddInfoVO && materialData.stuAuditAddInfoVO.addAuditOpinion">
                  <span>审核意见:</span>
                  <span>{{ materialData.stuAuditAddInfoVO.addAuditOpinion }}</span>
                </div>
                <van-button color="#1A7EE2" class="reEnroll reUpload" v-if="showAudit() == 2 &&  !reUpload" block @click="onReUpload()">重新上传资料
                </van-button>
              </template>
              <template v-if="materialData && !materialData.stuAuditAddInfoVO  || reUpload">
                <template v-for="(item, index) in preSetInfo.dataList">
                  <div class="uploadItem" v-if="getLoadItem(item, index)" :key="index">
                    <div class="uploadHead">
                      <div><span v-if="item.requiredFlag == 1" class="red">*</span>{{ item.name }}<span
                          v-if="item.requiredFlag == 0">(选填)</span></div>
                      <div class="example" v-if="item.description || item.mustDescription"
                        @click="handleExampleClick(item, index)">
                        <img src="@/assets/img/tips.png" alt="" srcset="">查看样例
                      </div>
                    </div>
                    <div class="uploadMain">
                      <div class="uploadImg" v-for="(uploadItem, uploadIndex) in item.uploadSet" :key="uploadIndex">
                        <div class="uploadImgBox">
                          <div v-if="reUpload?!uploadItem.addValueNew:!uploadItem.addValue" class="addImg" @click="handleUploadClick(item, uploadIndex)">
                            <img class="add" src="@/assets/img/preAdd.png" alt="" srcset="">
                            <div class="bgImg"
                              :style="[{ 'background': 'url(' + uploadItem.bgImg + ') no-repeat' || '' }]">
                            </div>
                          </div>
                          <template v-else>
                            <van-image fit="contain" :src="reUpload?uploadItem.addValueNew:uploadItem.addValue"
                              @click="handleUploadClick(item, uploadIndex)" />
                          </template>
                        </div>
                        <div class="text">{{ uploadItem.text }}</div>
                      </div>
                    </div>
                  </div>
                </template>
                <div :class="['btn', { 'center': showAudit() !== 2 }]">
                  <van-button color="#1A7EE2" class="btnItem reEnroll" block @click="showConfirm=!showConfirm" v-if="preSetInfo.dataList.length">确认提交
                  </van-button>
                  <van-button class="btnItem " type="default" @click="reUpload=!reUpload" v-if="showAudit() == 2">
                    稍后上传
                  </van-button>
                  <!-- :native-type="preSetInfo && preSetInfo.dataList.length == 0 ? 'submit' : 'button'" -->
              </div>
              </template>
              <template v-else>
                <template v-for="(item, index) in materialData.stuAuditInfoVO.extendList">
                  <div class="preContentItem preContentItemColumn" :key="index"
                    v-if="item.type == 2 && item.displayFlag == 1 && item.addValue">
                    <span class="preInfoLable">{{ item.name }}:</span>
                    <div>
                      <van-image fit="contain" class="infoImg" v-for="(imgItem, imgIdx) in item.addValues" :key="imgIdx"
                        :src="imgItem" @click="onPreview(item.addValues, 'arr')" />
                    </div>
                  </div>
                </template>
              </template>
            </van-tab>
          </van-tabs>
        </div>
        <div class="preContentBox preContentBox2 center" v-if="!showMaterials || showQuery() > 1">
          <span class="blue pointer" @click="backApply()">{{ showQuery() > 1 ? '返回报名页面' : '>>查看报名结果 / 补充材料'
          }}
          </span>
        </div>
        <div class="preContentFoot">
          <div class="preConFootItem">
            <div class="icon">
              <span class="iconW"><img src="@/assets/img/phone.png" alt="" srcset=""></span>
              <span>联系电话</span>
            </div>
            <div class="text">
              <template v-if="!$route.query.serviceType">
                <div v-for="(item, index) in schoolInfo.phones" :key="index">{{ item }}</div>
              </template>
              <div v-else-if="stationList[0]">{{ stationList[0].aidPhone || '暂无' }}</div>
            </div>
          </div>
          <div class="preConFootItem">
            <div class="icon">
              <span class="iconW"><img src="@/assets/img/site.png" alt="" srcset=""></span>
              <span>联系地址</span>
            </div>
            <div class="text" v-if="!$route.query.serviceType">{{ schoolInfo.address || '暂无' }}</div>
            <div class="text" v-else-if="stationList[0]">{{ stationList[0].address || '暂无' }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="preFooter">
      <!-- <div class="preFootH">
        <img src="@/assets/img/zhxtLogo.png" alt=""> <span>平台驱动</span>
      </div> -->
      <div class="preFootMain">
        <div class="fLeft">
          <!-- <div>© 2024-2027 广州传知汇科技有限公司</div> -->
          <div><a href="https://beian.miit.gov.cn/" target="_blank">备案号：粤ICP备2024264514号</a></div>
          <!-- <div>
            <img src="@/assets/img/filing.png" alt="" srcset="">
            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010602010088" target="_blank">
              <span>粤公网安备 44010602010088号</span>
            </a>
          </div>
          <div>教APP备4401799号</div> -->
        </div>
        <div class="fRight">
          <!-- <img src="@/assets/img/zhxtApp.png" alt="">
          <div>下载智汇学堂APP助力成考</div> -->
        </div>
      </div>
    </div>
    <!-- 禁止报名提示-->
    <div class="tipWall" v-if="showWall()" :style="[{ height: getH() }]" @click="onTipWall()"></div>
    <!-- 选择器 -->
    <van-popup v-model:show="showPicker" position="bottom">
      <van-picker show-toolbar :value-key="pickerKey" :columns="pickerList" @change="onChangePicker"
        @confirm="onConfirmPicker" @cancel="showPicker = false" />
    </van-popup>
    <!-- 地区 -->
    <van-popup v-model:show="showArea" position="bottom">
      <van-cascader v-model="formLine.regionId" title="请选择所在地区" :options="areaList" @close="showArea = false"
        @change="onAreaChange" @finish="onAreaFinish" />
    </van-popup>
    <!-- 确认提交 -->
    <van-dialog v-model:show="showConfirm" show-cancel-button title="提示" confirm-button-text="确认提交" confirm-button-color="#1A7EE2"
    @confirm="showConfirm=!showConfirm,onSubmitMaterial()" @cancel="showConfirm=!showConfirm" >
      <div class="exampleItem1">是否确认提交当前补充材料？</div>
    </van-dialog>
    <!-- 校验提示 -->
    <van-dialog v-model:show="showTips" title="提示" confirm-button-text="返回页面修改" confirm-button-color="#1A7EE2">
      <div class="exampleItem1">{{ tipMsg }}</div>
    </van-dialog>
    <!-- 提交成功 -->
    <van-dialog v-model:show="showSuccess" title="提交成功" confirm-button-text="我已知悉" confirm-button-color="#1A7EE2">
      <div class="exampleItem1" v-if="preSetInfo && preSetInfo.successMsg" v-html="preSetInfo.successMsg"></div>
      <div class="exampleItem1" v-else>您已成功完成报名！审核结果将通过手机短信方式通知您，请注意查收。</div>
    </van-dialog>
    <!-- 材料弹窗 -->
    <van-dialog v-model:show="showExample" :title="example.name" confirm-button-color="#999999">
      <div class="exampleItem" v-if="example.description">
        <div v-html="example.description"></div>
      </div>
      <div class="exampleItem" v-else-if="example.name == '毕业照片'">
        <div>请上传本人对应现最高学历学校毕业证明照片。</div>
        <div>材料请上传jpg,jpeg,bmp,png其中一种类型的图片。</div>
      </div>
      <div class="exampleItem0" v-else-if="example.name == '学历证书电子注册备案表'">
        <div>请登录学信网获取备案表上传，例图如下。</div>
        <img src="@/assets/img/preExample1.png" />
      </div>
      <div class="exampleItem1" v-else-if="example.name == '异地报名证明材料'">
        <div>跨省或跨市报考，需提供居住证或者社保卡证明。</div>
        <div>材料请上传jpg,jpeg,bmp,png其中一种类型的图片。例图如下。</div>
        <img src="@/assets/img/idCard1.png" />
        <img src="@/assets/img/idCard2.png" />
      </div>
      <div class="exampleItem" v-else-if="example.name == '临床类专业资料'">
        <div>
          报考临床类（包括临床医学、口腔医学、预防医学、中医学等专业）的，须提交省、市级卫生健康行政部门颁发的相应类别的执业助理医师及以上资格证书或取得国家认可的普通中专及以上相应专业学历；或者县级及以上卫生健康行政部门颁发的乡村医生执业证书并具有中专学历或中专水平证书。
        </div>
        <div>材料请上传jpg,jpeg,bmp,png其中一种类型的图片。</div>
      </div>
      <div class="exampleItem" v-else-if="example.name == '护理学类专业资料'">
        <div>报考护理学类的，须提交县级及以上卫生健康行政部门颁发的执业护士证书。</div>
        <div>材料请上传jpg,jpeg,bmp,png其中一种类型的图片。</div>
      </div>
      <div class="exampleItem" v-else-if="example.name == '医学专业从业证明'">
        <div>报考医学门类其他专业的，须提交从事卫生、医药行业工作的在职专业技术人员单位证明。</div>
        <div>材料请上传jpg,jpeg,bmp,png其中一种类型的图片。</div>
      </div>
    </van-dialog>
    <!-- 照片预览 -->
    <van-image-preview v-model="preview" :images="previewList" @change="onPreview">
      <template v-slot:index>{{ previewIdx }}/{{ previewList.length }}</template>
    </van-image-preview>
    <!-- 上传选择 -->
    <van-action-sheet v-model="showUploadWay" :actions="actions" cancel-text="取消" close-on-click-action
      @select="onSelect" />
    <!--打开相机-->
    <input style="display: none" type="file" ref="videoDom" accept="image/*" capture="camera" @change="videoChange">

    <!-- 调用相机和相册 -->
    <input style="display: none" type="file" ref="imgDom" accept="image/*" @change="imgChange">

  </div>
</template>
<script>
import { Toast } from 'vant'
import uploadAli from '@/api/uploadAli.js'
export default {
  name: 'preRegistration',
  data () {
    return {
      pickerKey: 'name',
      pickerModel: 'station',
      schoolInfo: {},
      example: {},
      showMenu: false,
      showUploadWay: false,
      showSuccess: false,
      showConfirm: false,
      showTips: false,
      showExample: false,
      showArea: false,
      showPicker: false,
      showMaterials: false,
      preview: false,
      sent: false,
      reUpload: false,
      sentNumL: 60,
      exampleUpIdx: 0,
      step: 1,
      tipMsg: '',
      actions: [
        { name: '拍照', value: 'video' },
        { name: '相册上传', value: 'image' }
      ],
      formLine: {},
      rules: {
        name: [{ required: true, trigger: 'blur' }],
        tips: [{ required: true, trigger: 'blur' }],
        cardId: [{ validator: this.handleRules('cardId'), required: true, trigger: 'onBlur' }],
        phone: [{ validator: this.handleRules('phone'), required: true, trigger: 'onBlur' }]
      },
      pickerList: [],
      stationList: [],
      batchList: [],
      majorList: [],
      areaList: [],
      cityList: [],
      eduCategory: {
        0: '专升本',
        1: '高起本',
        2: '高起专',
        3: '中专'
      },
      eduCategoryList: [
        {
          label: '高起专',
          id: 2
        },
        {
          label: '专升本',
          id: 0
        },
        {
          label: '高起本',
          id: 1
        },
        {
          label: '中专',
          id: 3
        }
      ],
      preSetInfo: null,
      materialActive: 'a',
      materialData: {},
      materialList: [],
      queryInfos: [
        {
          label: '姓名',
          value: 'name'
        },
        {
          label: '身份证明',
          value: 'cardId'
        },
        {
          label: '报名教学点',
          value: 'stationName'
        },
        {
          label: '报名批次',
          value: 'admissionBatch'
        },
        {
          label: '报名层次',
          value: 'eduCategoryValue'
        },
        {
          label: '报名专业',
          value: 'majorName'
        },
        {
          label: '户籍所在地',
          value: 'censusRegister'
        },
        {
          label: '联系电话',
          value: 'phone'
        },
        {
          label: '报名时间',
          value: 'commentTime'
        }
      ],
      heplStuInfos: [
        {
          label: '助学账号',
          value: 'loginNum'
        },
        {
          label: '助学课程',
          value: 'assistiveCourses'
        },
        {
          label: '电脑端学习地址',
          value: 'studyAddress',
          tips: '（仅电脑打开可学习）'
        },
        {
          label: 'APP下载地址',
          value: 'appCode'
        }
      ],
      previewIdx: 1,
      previewList: []
    }
  },
  mounted () {
    this.getSchoolInfo()
  },
  destroyed () {
    sessionStorage.removeItem('code')
  },
  methods: {
    onBtnOS (os) {
      if (os == 'download') {
        window.location.replace('http://h5.gdzk5184.com/#/ordinaryDown')
      } else if (os == 'copy') {
        const url = this.materialData.stuAssistanceVO.studyAddress || '' // 后台接口返回的链接地址
        this.$copyText(url).then(
          function (e) {
            console.log('copy arguments e:', e)
            Toast.success('复制成功')
          },
          function (e) {
            console.log('copy arguments e:', e)
            Toast.fail('复制失败')
          }
        )
      }
    },
    // 返回报名
    backApply () {
      this.showMaterials = !this.showMaterials
      // this.formLine = {}
      this.$set(this.formLine, 'code', null)
      this.$set(this, 'materialData', {})
      this.$set(this, 'materialList', [])
    },
    // 获取补充材料审核状态
    showAudit (type) {
      if (type == 'add') {
        // 获取是否上传补充材料状态
        // 3通过
        if (this.showQuery() == 3 && this.preSetInfo && this.preSetInfo.openMaterialStatus && this.materialData.stuAuditInfoVO.id) {
          return true
        } else {
          return false
        }
      } else {
        // 获取状态
        let val = null
        if (this.materialData.stuAuditAddInfoVO) {
          val = this.materialData.stuAuditAddInfoVO.addAuditStatus
        } else {
          // return false
        }
        return val
      }
    },
    onPreview (val, type) {
      if (type == 'arr') {
        this.preview = !this.preview
        this.previewList = val
      } else {
        this.previewIdx = val + 1
      }
    },
    onTipMsg (msg) {
      this.tipMsg = msg
      this.showTips = !this.showTips
    },
    onClickArea (type) {
      if (this.preSetInfo.domicileSetVO.province && this.preSetInfo.domicileSetVO.city) {
        // 省市默选
      } else if (this.preSetInfo.domicileSetVO.province && !this.preSetInfo.domicileSetVO.city) {
        // 省份默选 只选择市区
        this.handleModel('city')
      } else {
        // 省市区放开
        if (type == 2) {
          // 市区
          if (!this.formLine.provinceName) {
            return this.onTipMsg('请先选择省份')
          }
          this.handleModel('city')
        } else {
          // 省
          this.handleModel('province')
        }
      }
    },
    onFieldBlur (item) {
      console.log(item, 'item')
      // console.log(this.preSetInfo.infoList);
      if (item.wordMax && (item.wordMin || item.wordMin == 0)) {
        if (item.value.length < item.wordMin || item.value.length > item.wordMax) {
          this.onTipMsg(item.name + '字符限制' + item.wordMin + '—' + item.wordMax + '位，当前字数不符')
        }
      }
      if (item == 'cardId') {
        if (this.formLine.cardId && this.formLine.cardId.length !== 18 && this.formLine.cardId.length !== 15) {
          this.onTipMsg('身份证格式不正确')
        }
      }
    },
    getH () {
      console.log(this.$refs)
      const h = this.$refs.preMain.clientHeight + 100 + 'px'
      return h
    },
    showWall () {
      if (this.preSetInfo && this.preSetInfo.startTime && this.preSetInfo.endTime) {
        // 有时间限制
        const d1 = new Date().getTime()
        if (parseInt(this.preSetInfo.startTime) < d1 && parseInt(this.preSetInfo.endTime) > d1) {
          // 报名时间内
          return false
        } else {
          if (this.showMaterials) {
            return false
          } else {
            return true
          }
        }
      } else {
        return false
      }
    },
    showQuery (type) {
      if (this.showMaterials) {
        if (type === 'material') {
          const str = 'value'
          let num = 0
          this.materialData.stuAuditInfoVO.extendList.forEach(item => {
            if (item[str]) {
              num++
            }
          })
          return num !== 0
        }
        if (this.materialData.stuAuditInfoVO && (this.materialData.stuAuditInfoVO.auditStatusValue || this.materialData.stuAuditInfoVO.auditStatusValue === 0)) {
          // return 2
          if (this.materialData.stuAuditInfoVO.auditStatusValue === 0) {
            return 2
          } else if (this.materialData.stuAuditInfoVO.auditStatusValue === 1) {
            return 3
          } else if (this.materialData.stuAuditInfoVO.auditStatusValue === 2) {
            return 4
          }
        } else {
          return 1
        }
      } else {
        return false
      }
    },
    onTipWall () {
      const d1 = new Date().getTime()
      if (parseInt(this.preSetInfo.startTime) > d1) {
        // 未开始报名
        return this.onTipMsg('报名时间未开始，请稍后刷新报名')
      } else if (parseInt(this.preSetInfo.endTime) < d1) {
        return this.onTipMsg('报名时间已截止')
      } else {
        // return this.onTipMsg('身份证格式不正确')
      }
    },
    handleFinish () {
      this.sent = !this.sent
    },
    handleRules (str) {
      // 校验
      console.log(str, 'str', this.formLine)
      if (this.formLine) {
        // if (!this.formLine[str]) return
        // if (str == 'cardId' && !this.$way.regIDCard(this.formLine.cardId)) {
        //   this.formLine.cardId = null
        //   this.Warn('身份证格式有误')
        //   // return
        // } else if (str == 'phone' && !this.$way.regPhoneNew(this.formLine.phone)) {
        //   // this.formLine.phone = null
        //   // this.Warn('手机号码格式有误')
        //   // return
        // }
      }
      console.log(str)
    },
    // 模块展示
    getLoadItem (item, index) {
      const fill = {
        1: '报考专升本层次',
        2: '报考地与户籍地不同',
        3: '报考临床类专业',
        4: '报考护理学类专业',
        5: '报考医学类专业'
      }
      let info = this.formLine
      // console.log(item, index, 'getLoadItem', );
      if (this.showMaterials) {
        info = this.materialData.stuAuditInfoVO || {}
        if (item.openMaterialWrite === 0) {
          return false
        }
      } else {
        if (item.displayFlag === 0) {
          return false
        }
      }
      if (item.fillConditions == 1) {
        if (info.eduCategory === 0) {
          this.preSetInfo.dataList[index].show = true
          return true
        } else {
          this.preSetInfo.dataList[index].show = false
          return false
        }
      } else if (item.fillConditions == 2) {
        if (this.formLine.regionId != parseInt(this.formLine.stationRegionId) && !this.formLine.regionIdName || this.formLine.regionIdName && this.formLine.regionIdName != this.formLine.stationProvince) {
          this.preSetInfo.dataList[index].show = true
          return true
        } else {
          this.preSetInfo.dataList[index].show = false
          return false
        }
      } else if (item.fillConditions == 3) {
        if (this.formLine.medicineMajor) {
          if (this.formLine.medicineMajor == '临床类') {
            this.preSetInfo.dataList[index].show = true
            return true
          } else {
            this.preSetInfo.dataList[index].show = false
            return false
          }
        } else {
          this.preSetInfo.dataList[index].show = false
          return false
        }
      } else if (item.fillConditions == 4) {
        if (this.formLine.medicineMajor) {
          if (this.formLine.medicineMajor == '护理学类') {
            this.preSetInfo.dataList[index].show = true
            return true
          } else {
            this.preSetInfo.dataList[index].show = false
            return false
          }
        } else {
          this.preSetInfo.dataList[index].show = false
          return false
        }
      } else if (item.fillConditions == 5) {
        if (this.formLine.medicineMajor) {
          if (this.formLine.medicineMajor == '临床类' || this.formLine.medicineMajor == '护理学类') {
            if (item.additionalInfo && item.additionalInfo.noSupportMedicineMajor.indexOf(this.formLine.medicineMajor) !== -1) {
              this.preSetInfo.dataList[index].show = false
              return false
            } else {
              this.preSetInfo.dataList[index].show = true
              return true
            }
          } else if (this.formLine.medicineMajor == '非临床和护理学类医学') {
            this.preSetInfo.dataList[index].show = true
            return true
          } else {
            this.preSetInfo.dataList[index].show = false
            return false
          }
        } else {
          this.preSetInfo.dataList[index].show = false
          return false
        }
      } else {
        console.log('xianshi', item, index)
        return true
      }
    },
    // 校验提交
    onFiedSubmit (val) {
      console.log(val, 'object', this.formLine)
      console.log(this.preSetInfo.infoList)
      if (this.step == 1) {
        if (this.preSetInfo.infoList.length > 0) {
          for (const i in this.preSetInfo.infoList) {
            const obj = this.preSetInfo.infoList[i]
            if (obj.requiredFlag == 1 && obj.displayFlag == 1 && (obj.fieldType == 1 ? !(this.formLine[obj.fieldName] || this.formLine[obj.fieldName] === 0) : !obj.value)) {
              console.log(obj, i, 'obj')
              console.log(this.formLine[obj.fieldName])
              return this.onTipMsg(obj.name + '未填写或选择')
            }
            if (obj.fieldName == 'cardId') {
              if (this.formLine.cardId.length !== 18 && this.formLine.cardId.length !== 15) {
                return this.onTipMsg('身份证格式不正确')
              }
            }
            if ((obj.wordMax || obj.wordMin) && (obj.fieldType == 1 ? (this.formLine[obj.fieldName].length > obj.wordMax || this.formLine[obj.fieldName].length < obj.wordMin) : (obj.value.length > obj.wordMax || obj.value.length < obj.wordMin))) {
              console.log(obj, i)
              console.log(this.formLine[obj.fieldName])
              return this.onTipMsg(obj.name + '填写字数需' + obj.wordMin + '-' + obj.wordMax)
            }
          }
        }
        if (this.preSetInfo && this.preSetInfo.dataList.length == 0) {
          // 无资料上传，直接提交
          if (val == 'submit') {
            this.onSubmitApply()
          }
        } else {
          this.step = 2
        }
      } else if (this.step == 2) {
        console.log('提交')
        if (val == 'submit') {
          this.onSubmitApply()
        } else {
          this.step = 1
        }
      }
    },
    // 预报名提交信息
    onSubmitApply () {
      // 材料校验是否齐全
      if (this.preSetInfo.dataList.length > 0) {
        for (const i in this.preSetInfo.dataList) {
          const obj = this.preSetInfo.dataList[i]
          if (obj.displayFlag == 1) {
            if (obj.uploadSet.length > 0) {
              if (obj.requiredFlag == 1 && obj.show) {
                for (const j in obj.uploadSet) {
                  if (!obj.uploadSet[j].imgUrl) {
                    console.log(obj)
                    this.onTipMsg(obj.name + '还有材料未上传')
                    return
                  }
                }
              }
            }
          }
        }
      }
      const data = {
        ...this.formLine,
        extendList: []
      }
      if (this.preSetInfo.dataList.length > 0) {
        for (const i in this.preSetInfo.dataList) {
          const obj = this.preSetInfo.dataList[i]
          if (obj.displayFlag == 1 && obj.show) {
            const arr = []
            for (const j in obj.uploadSet) {
              if (obj.uploadSet[j].imgUrl) {
                arr.push(obj.uploadSet[j].imgUrl)
              }
            }
            const value = arr.length > 0 ? arr.join(',') : null
            const a = {
              name: obj.name,
              type: obj.type,
              setId: obj.id,
              value: value
            }
            if (value) {
              data.extendList.push(a)
            }
          }
        }
      }
      if (this.preSetInfo.infoList.length > 0) {
        for (const i in this.preSetInfo.infoList) {
          const obj = this.preSetInfo.infoList[i]
          const a = {
            name: obj.name,
            type: obj.type,
            setId: obj.id,
            value: obj.value
          }
          if (obj.value && obj.displayFlag == 1) {
            data.extendList.push(a)
          }
        }
      }
      console.log(data, 'data')
      this.$request.postPreRegistrationApply(data).then((res) => {
        if (res.data.code == 0) {
          this.showSuccess = !this.showSuccess
        }
      }).catch((err) => {
        console.log(err.response, 'response', err.response)
        if (err.response.data.msg) {
          this.onTipMsg(err.response.data.msg)
        }
      })
    },
    onFailed (val) {
      console.log(val, 'onFailed')
      if (this.preSetInfo.infoList.length > 0) {
        for (const i in this.preSetInfo.infoList) {
          const obj = this.preSetInfo.infoList[i]
          if (obj.requiredFlag == 1 && obj.displayFlag == 1 && (obj.fieldType == 1 ? !(this.formLine[obj.fieldName] || this.formLine[obj.fieldName] === 0) : !obj.value)) {
            console.log(obj, i, 'obj')
            console.log(this.formLine[obj.fieldName])
            return this.onTipMsg(obj.name + '未填写或选择')
          }
          if (obj.fieldName == 'cardId') {
            if (this.formLine.cardId.length !== 18 && this.formLine.cardId.length !== 15) {
              return this.onTipMsg('身份证格式不正确')
            }
          }
          if ((obj.wordMax || obj.wordMin) && (obj.fieldType == 1 ? (this.formLine[obj.fieldName].length > obj.wordMax || this.formLine[obj.fieldName].length < obj.wordMin) : (obj.value.length > obj.wordMax || obj.value.length < obj.wordMin))) {
            console.log(obj, i)
            console.log(this.formLine[obj.fieldName])
            return this.onTipMsg(obj.name + '填写字数需' + obj.wordMin + '-' + obj.wordMax)
          }
        }
      }
    },
    // 提交查询
    onSubmitQuery () {
      console.log('onSubmitQuery')
      const data = {
        cardId: this.formLine.cardId,
        code: this.formLine.code,
        phone: this.formLine.phone,
        stationId: this.formLine.stationId
      }
      if (!data.cardId || data.cardId.length !== 18 && data.cardId.length !== 15) {
        return this.onTipMsg('身份证格式不正确')
      }
      if (!data.phone) {
        return this.onTipMsg('手机号未填写或选择')
      }
      if (!data.code) {
        return this.onTipMsg('验证码未填写或选择')
      }
      if (!data.stationId) {
        return this.onTipMsg('教学点未填写或选择')
      }
      // if (this.$route.query.code) {
      //   this.getQueryInfo(this.$route.query.code)
      // } else {
      // }
      this.getCode(data)
    },
    getCode (data) {
      this.$request.postPreRegistrateCode(data).then((res) => {
        if (res.data.code == 0) {
          this.getQueryInfo({ code: res.data.data })
        }
      }).catch((err) => {
        console.log(err.response, 'response', err.response)
        if (err.response.data.msg) {
          this.onTipMsg(err.response.data.msg)
        }
      })
    },
    getQueryInfo (obj) {
      sessionStorage.setItem('code', obj.code)
      this.$request.postPreRegistrationQuery(obj).then((res) => {
        if (res.data.code == 0) {
          // this.showSuccess = !this.showSuccess
          if (!this.showMaterials && this.showAudit() !== 2) {
            this.showMaterials = !this.showMaterials
          }
          const newData = res.data.data[0] || null
          this.materialData = newData
          this.materialList = res.data.data
        } else {
          // if (res.data.msg) {
          //   return this.onTipMsg(res.data.msg)
          // }
        }
      }).catch((err) => {
        console.log(err.response, 'response', err.response)
        if (err.response.data.msg) {
          this.onTipMsg(err.response.data.msg)
        }
      })
    },
    // 选择器初始化
    handleModel (val, list) {
      this.pickerModel = val
      if (val == 'station') {
        this.pickerList = this.stationList
        this.pickerKey = 'name'
        this.showPicker = !this.showPicker
      } else if (val == 'province') {
        this.pickerList = this.areaList
        this.pickerKey = 'name'
        this.showPicker = !this.showPicker
      } else if (val == 'city') {
        this.pickerList = this.cityList
        this.pickerKey = 'name'
        this.showPicker = !this.showPicker
      } else if (val == 'batch') {
        if (!this.formLine.stationId) {
          for (let i = 0; i < this.preSetInfo.infoList.length; i++) {
            const item = this.preSetInfo.infoList[i]
            if (item.fieldName == 'stationId') {
              this.onTipMsg('请先选择' + item.name)
              return
            }
          }
        } else {
          this.pickerList = this.batchList
          this.pickerKey = 'admissionBatch'
          this.showPicker = !this.showPicker
        }
      } else if (val == 'queryBatch') {
        this.pickerList = this.materialList
        this.pickerKey = 'admissionBatch'
        this.showPicker = !this.showPicker
      } else if (val == 'eduCategory') {
        if (!this.formLine.stationId) {
          for (let i = 0; i < this.preSetInfo.infoList.length; i++) {
            const item = this.preSetInfo.infoList[i]
            if (item.fieldName == 'stationId') {
              this.onTipMsg('请先选择' + item.name)
              return
            }
          }
        }
      } else if (val == 'education') {
        if (!this.formLine.admissionBatch) {
          this.onTipMsg('请先选择批次')
        }
      } else if (val == 'major') {
        console.log(this.formLine.eduCategory, 'this.formLine.eduCategory')
        if ((this.formLine.eduCategory || this.formLine.eduCategory == 0) && this.formLine.eduCategory !== undefined) {
          this.pickerKey = 'majorName'
          this.pickerList = this.majorList.map(item => {
            return {
              ...item,
              disabled: item.optionalFlag != 1
            }
          })
          const arr = JSON.parse(JSON.stringify(this.pickerList)) || []
          const length = arr.length !== 0 ? arr.length - 1 : 0
          let num = 0
          for (let i = 0; i < arr.length - 2; i++) {
            const item = arr[i]
            if (item.disabled && i !== length) {
              num++
              this.pickerList.push(item)
              this.pickerList.splice(i - num + 1, 1)
            }
          }
          this.showPicker = !this.showPicker
        } else {
          this.onTipMsg('请先选择批次和学历')
        }
      } else if (val.indexOf('diyItem') != -1) {
        this.pickerList = list ? JSON.parse(list) : []
        this.pickerKey = 'diyItem'
        this.showPicker = !this.showPicker
      }
    },
    onChangePicker (val, idx) {
      console.log(val, idx)
    },
    // 选择器确认
    onConfirmPicker (val, idx) {
      console.log(val, idx, 'onConfirmPicker', this.pickerModel)
      if (this.pickerModel == 'batch') {
        this.getPreMajorList(val.admissionBatch)
        this.$set(this.formLine, 'admissionBatch', val.admissionBatch)
      } else if (this.pickerModel == 'queryBatch') {
        // this.getPreMajorList(val.admissionBatch)
        this.$set(this, 'materialData', val)
        this.$set(this, 'materialActive', 'a')
        this.showPicker = !this.showPicker
      } else if (this.pickerModel == 'province') {
        this.onAreaChange({ value: val.code })
        this.$set(this.formLine, 'provinceId', val.id)
        this.$set(this.formLine, 'provinceName', val.name)
        this.$set(this.formLine, 'regionId', null)
        this.$set(this.formLine, 'cityName', null)
        this.onProvince(val.name)
        console.log(this.formLine)
        this.showPicker = !this.showPicker
      } else if (this.pickerModel == 'city') {
        this.$set(this.formLine, 'regionId', val.id)
        this.$set(this.formLine, 'cityName', val.name)
        if (this.formLine.provinceName) {
          this.onProvince(this.formLine.provinceName)
        }
        console.log(this.formLine)
        this.showPicker = !this.showPicker
      } else if (this.pickerModel == 'station') {
        this.$set(this.formLine, 'stationId', val.id)
        this.$set(this.formLine, 'stationRegionId', val.regionId)
        this.$set(this.formLine, 'stationProvince', val.province)
        this.$set(this.formLine, 'stationName', val.name)
        this.$set(this.formLine, 'admissionBatch', null)
        this.$set(this.formLine, 'education', null)
        this.$set(this.formLine, 'eduCategory', null)
        this.$set(this.formLine, 'majorId', null)
        this.$set(this.formLine, 'majorName', null)
        this.$set(this.formLine, 'medicineMajor', null)
        this.showPicker = !this.showPicker
      } else if (this.pickerModel == 'major') {
        if (val.optionalFlag != 1) {
          return this.onTipMsg(val.majorName + '已被禁用')
        }
        this.$set(this.formLine, 'majorId', val.majorId)
        this.$set(this.formLine, 'majorName', val.majorName)
        this.$set(this.formLine, 'medicineMajor', val.medicineMajor)
        this.showPicker = !this.showPicker
      } else if (this.pickerModel.indexOf('diyItem') != -1) {
        const index = Number(this.pickerModel.substring(7))
        this.$set(this.preSetInfo.infoList[index], 'value', val)
        // this.$set(this.formLine, 'majorName', val.majorName)
        // this.$set(this.formLine, 'medicineMajor', val.medicineMajor)
        this.showPicker = !this.showPicker
      }
    },
    // 对特殊区域处理
    onProvince (name) {
      if (name == '北京市' || name == '天津市' || name == '上海市' || name == '重庆市') {
        this.$set(this.formLine, 'regionIdName', name)
      } else {
        this.$set(this.formLine, 'regionIdName', null)
      }
    },
    onAreaFinish (val) {
      console.log(val, 'onAreaFinish')
      this.showArea = !this.showArea
      const area = val.selectedOptions[0].name + '/' + val.selectedOptions[1].name
      this.$set(this.formLine, 'area', area)
      this.$set(this.formLine, 'regionId', val.selectedOptions[1].id)
      const name = val.selectedOptions[0].name
      this.onProvince(name)
    },
    handleMenu (val) {
      console.log(val, 'val')
      this.showMenu = !this.showMenu
    },
    // 点击上传图片
    handleUploadClick (item, index) {
      this.example = item
      this.exampleUpIdx = index
      this.showUploadWay = !this.showUploadWay
      console.log(this.example, this.exampleUpIdx, 'handleUploadClick')
    },
    // 样例点击
    handleExampleClick (item, index) {
      // this.example.idx = index
      console.log(item, index)
      this.example = item
      this.showExample = !this.showExample
      console.log('sss', this.example)
    },

    // 翻页
    changeStep (step) {
      if (this.step == 1) {
        console.log(this.formLine, 'formLine')
      }
      // this.step = step
    },

    // 发送验证码
    getSendCode () {
      if (!this.formLine.phone) {
        return this.onTipMsg('请先输入手机号码')
      }
      this.$request.getSendCode({
        mobile: this.formLine.phone
      }).then((res) => {
        if (res.data.code == 0) {
          this.sent = true
          // this.sentNum = 6
          // let num = 6
          // const timer = setInterval(() => {
          //   num--
          //   // this.sentNum = num
          //   this.$set(this, 'sentNum', num)
          //   if (this.sentNum == 0) {
          //     this.sent = false
          //     this.$once('hook:beforeDestroy', () => {
          //       clearInterval(timer)
          //     })
          //   }
          //   console.log(this.sentNum)
          // }, 1000)
        } else {
          return this.onTipMsg(res.data.msg)
        }
      }).catch((err) => {

      })
    },
    // 获取省份
    getPreRegistrationProvinces () {
      this.$request.getPreRegistrationProvinces().then((res) => {
        if (res.data.code == 0) {
          const list = res.data.data
          this.areaList = list
        }
      }).catch((err) => {
      })
    },
    onAreaChange (value) {
      // 获取市区县
      this.$request.getPreRegistrationCities(
        { parentCode: value.value }
      ).then((res) => {
        if (res.data.code == 0) {
          const list = res.data.data
          this.cityList = list
          if (this.materialData.stuAuditInfoVO.regionId) {
            for (const i in list) {
              const item = list[i]
              if (item.id == this.materialData.stuAuditInfoVO.regionId) {
                this.formLine.regionId = item.id
                this.formLine.cityName = item.name
              }
            }
          }
        }
      }).catch((err) => {

      })
    },
    // 获取教学点下拉
    getStationList () {
      this.$request.getPreRegistrationStations({
        id: this.$route.query.serviceType || null,
        currentPage: 1,
        pageSize: 500
      }).then((res) => {
        if (res.data.code == 0) {
          this.stationList = JSON.parse(JSON.stringify(res.data.data.records))
          if (this.$route.query.serviceType) {
            this.formLine.stationId = this.stationList[0].id
            this.formLine.stationRegionId = this.stationList[0].regionId
          }
        }
      }).catch((err) => {

      })
    },
    // 获取招生计划列表
    getEnrollmentPlanList () {
      this.$request.getPreEnrollmentPlanList({
        currentPage: 1,
        pageSize: 500
      }).then((res) => {
        if (res.data.code == 0) {
          const list = JSON.parse(JSON.stringify(res.data.data.records))
          this.batchList = list.filter(item => {
            return item.registrationPageDisplay == 1
          })
          if (this.batchList.length == 1) {
            this.$set(this.formLine, 'admissionBatch', this.batchList[0].admissionBatch)
            this.getPreMajorList(this.batchList[0].admissionBatch)
          }
        }
      }).catch((err) => {

      })
    },
    // 获取招生设置信息
    getProSetInfo () {
      console.log('getProSetInfo')
      this.$request.getPreEnrollmentSetInfo().then((res) => {
        if (res.data.code == 0) {
          console.log(res.data.data, 'getProSetInfo')
          this.preSetInfo = res.data.data
          this.preSetInfo.infoList = this.preSetInfo.infoList.map(item => {
            return {
              ...item,
              value: null
            }
          })
          this.preSetInfo.dataList = this.preSetInfo.dataList.map(item => {
            let mustDescription = false
            if (item.name == '毕业照片' || item.fillConditions) {
              mustDescription = true
            }
            return {
              ...item,
              show: true,
              mustDescription: mustDescription,
              uploadSet: this.getImgList(item.name, item)
            }
          })
          if (this.preSetInfo.domicileSetVO.city) {
            this.formLine.regionId = this.preSetInfo.domicileSetVO.city.id
            this.onProvince(this.preSetInfo.domicileSetVO.province.name)
          } else if (this.preSetInfo.domicileSetVO.province) {
            // 限定省份
            this.onAreaChange({ value: this.preSetInfo.domicileSetVO.province.code })
            this.onProvince(this.preSetInfo.domicileSetVO.province.name)
          } else {
            this.getPreRegistrationProvinces()
          }
        }
      }).catch((err) => {

      })
    },
    getImgList (name, item) {
      const tipArr = JSON.parse(item.promptContent) || []
      const arr = []
      if (name == '身份证照片') {
        for (let i = 0; i < item.imgNum; i++) {
          if (i == 0) {
            arr.push({
              text: tipArr[i] ? tipArr[i] : '请上传身份证国徽面',
              bgImg: require('@/assets/img/idCardBg1.png')
            })
          } else if (i == 1) {
            arr.push({
              text: tipArr[i] ? tipArr[i] : '请上传身份证人像面',
              bgImg: require('@/assets/img/idCardBg2.png')
            })
          } else {
            arr.push({
              text: tipArr[i] ? tipArr[i] : '请上传相关图片'
            })
          }
        }
      } else if (name == '毕业照片') {
        for (let i = 0; i < item.imgNum; i++) {
          arr.push({
            text: tipArr[i] ? tipArr[i] : '请上传毕业证照片'
          })
        }
      } else if (name == '学历证书电子注册备案表') {
        for (let i = 0; i < item.imgNum; i++) {
          arr.push({
            text: tipArr[i] ? tipArr[i] : '请上传备案表照片'
          })
        }
      } else if (name == '异地报名证明材料') {
        for (let i = 0; i < item.imgNum; i++) {
          arr.push({
            text: tipArr[i] ? tipArr[i] : '请上传居住证/社保卡照片'
          })
        }
      } else if (name == '临床类专业资料') {
        for (let i = 0; i < item.imgNum; i++) {
          arr.push({
            text: tipArr[i] ? tipArr[i] : '请上传资格证照片'
          })
        }
      } else if (name == '护理学类专业资料') {
        for (let i = 0; i < item.imgNum; i++) {
          arr.push({
            text: tipArr[i] ? tipArr[i] : '请上传资格证照片'
          })
        }
      } else if (name == '医学专业从业证明') {
        for (let i = 0; i < item.imgNum; i++) {
          arr.push({
            text: tipArr[i] ? tipArr[i] : '请上传从业证明照片'
          })
        }
      } else {
        for (let i = 0; i < item.imgNum; i++) {
          arr.push({
            text: tipArr[i] ? tipArr[i] : '请上传相关图片'
          })
        }
      }
      return arr
    },
    // 获取层次、专业
    getPreMajorList (batch, eduCategory, obj) {
      this.$set(this.formLine, 'majorId', null)
      this.$set(this.formLine, 'majorName', null)
      if (batch || eduCategory) {
        this.$request.getPreMajorList({
          currentPage: 1,
          stationId: this.$route.query.serviceType || this.formLine.stationId || null,
          admissionBatch: batch,
          eduCategory: eduCategory,
          pageSize: 500
        }).then((res) => {
          if (res.data.code == 0) {
            if (batch && (eduCategory || eduCategory === 0)) {
              // 学历+批次存在
              this.majorList = JSON.parse(JSON.stringify(res.data.data.records))
              console.log(this.majorList, 'majorList')
            } else if (batch) {
              // 获取批次下层次
              // 遍历查询去重
              // this.eduCategoryList = JSON.parse(JSON.stringify(res.data.data.records))
              const { records } = res.data.data
              console.log(records, 'records')
              const list = [
                {
                  label: '高起专',
                  id: 2
                },
                {
                  label: '专升本',
                  id: 0
                },
                {
                  label: '高起本',
                  id: 1
                },
                {
                  label: '中专',
                  id: 3
                }
              ]
              let arr = records.map(item => {
                return item.eduCategory
              })
              arr = Array.from(new Set(arr))
              const newlist = list.filter(item => {
                return arr.indexOf(item.id) !== -1
              })
              this.eduCategoryList = newlist
              if (this.eduCategoryList.length == 1) {
                this.$set(this.formLine, 'eduCategory', this.eduCategoryList[0].id)
              }
              if (this.showPicker) {
                this.showPicker = !this.showPicker
              }
            } else {

            }
            if (obj) {
              this.formLine = {
                ...this.formLine,
                ...obj
              }
            }
          }
        }).catch((err) => {
        })
      }
    },
    // 获取当前学校信息
    getSchoolInfo () {
      const params = {
        // encoding: schoolName,
        encoding: this.$route.query.name
      }
      this.$request.getSchoolInfo(params).then((res) => {
        if (res.data.code == 0) {
          this.schoolInfo = res.data.data
          sessionStorage.setItem('tenant_id', this.schoolInfo.id)
          this.getStationList()
          this.getEnrollmentPlanList()
          this.getProSetInfo()
          this.getPreMajorList()
          const code = sessionStorage.getItem('code')
          if (this.$route.query.code && !code) {
            this.getQueryInfo({ code: this.$route.query.code })
          } else if (code) {
            this.getQueryInfo({ code: code })
          }
        } else if (res.data.code == 1) {
          // this.$route.push('404')
        }
      }).catch((err) => { })
    },
    // 上传方式选择
    onSelect (item) {
      console.log(item)
      if (item.value == 'video') {
        this.$refs.videoDom.click()
      } else if (item.value == 'image') {
        console.log(this.$refs)
        this.$refs.imgDom.click()
      }
    },
    // 图片上传
    async imgChange () {
      const inputDOM = this.$refs.imgDom
      const files = inputDOM.files[0]
      // let fileSize = files.size / 1024 / 1024
      // if(fileSize > 10) {
      //   this.Error('照片不大于10M')
      //   return
      // }
      const obj = await uploadAli.imgFileUploadOss(files, 'img')
      console.log('object', this.exampleUpIdx, obj)
      if (obj.excelUrl) {
        for (const i in this.preSetInfo.dataList) {
          if (this.example.id === this.preSetInfo.dataList[i].id) {
            if (this.materialList.length > 0 && !this.materialData.stuAuditAddInfoVO && this.showMaterials) {
              this.$set(this.preSetInfo.dataList[i].uploadSet[this.exampleUpIdx], 'addValue', obj.excelUrl)
            } else if (this.reUpload) {
              this.$set(this.preSetInfo.dataList[i].uploadSet[this.exampleUpIdx], 'addValueNew', obj.excelUrl)
            } else {
              this.$set(this.preSetInfo.dataList[i].uploadSet[this.exampleUpIdx], 'imgUrl', obj.excelUrl)
            }
            break
          }
        }
      }
      console.log(this.preSetInfo.dataList)
      this.$refs.imgDom.value = ''
    },
    // 拍照上传
    async videoChange () {
      const inputDOM = this.$refs.videoDom
      const files = inputDOM.files[0]
      const fileSize = files.size / 1024 / 1024
      if (fileSize > 10) {
        this.Error('照片不大于10M')
        return
      }
      const obj = await uploadAli.imgFileUploadOss(files, 'img')
      console.log(obj, '拍照985')
      if (obj.excelUrl) {
        for (const i in this.preSetInfo.dataList) {
          if (this.example.id === this.preSetInfo.dataList[i].id) {
            if (this.materialList.length > 0 && !this.materialData.stuAuditAddInfoVO && this.showMaterials) {
              this.$set(this.preSetInfo.dataList[i].uploadSet[this.exampleUpIdx], 'addValue', obj.excelUrl)
            } else if (this.reUpload) {
              this.$set(this.preSetInfo.dataList[i].uploadSet[this.exampleUpIdx], 'addValueNew', obj.excelUrl)
            } else {
              this.$set(this.preSetInfo.dataList[i].uploadSet[this.exampleUpIdx], 'imgUrl', obj.excelUrl)
            }
            break
          }
        }
      }
      this.$refs.videoDom.value = ''
    },
    // 重新上传资料
    onReUpload () {
      this.reUpload = !this.reUpload
    },
    // 重新报名
    onReEnroll () {
      this.showMaterials = !this.showMaterials
      this.step = 1
      let provinceName = ''
      const cityName = ''
      if (this.materialData.stuAuditInfoVO.provinceCode) {
        for (const i in this.areaList) {
          const item = this.areaList[i]
          if (item.code == this.materialData.stuAuditInfoVO.provinceCode) {
            provinceName = item.name
          }
        }
        this.onAreaChange({ value: this.materialData.stuAuditInfoVO.provinceCode })
      }
      this.formLine = {
        name: this.materialData.stuAuditInfoVO.name || null,
        phone: this.materialData.stuAuditInfoVO.phone || null,
        cardId: this.materialData.stuAuditInfoVO.cardId || null,
        stationProvince: this.materialData.stuAuditInfoVO.stationProvince || null,
        stationRegionId: this.materialData.stuAuditInfoVO.stationRegionId || null,
        stationId: this.materialData.stuAuditInfoVO.stationId || null,
        stationName: this.materialData.stuAuditInfoVO.stationName || null,
        admissionBatch: this.materialData.stuAuditInfoVO.admissionBatch || null,
        eduCategory: this.materialData.stuAuditInfoVO.eduCategory || null,
        education: this.materialData.stuAuditInfoVO.education || null,
        majorId: this.materialData.stuAuditInfoVO.majorId || null,
        majorName: this.materialData.stuAuditInfoVO.majorName || null,
        regionId: this.materialData.stuAuditInfoVO.regionId || null,
        provinceName: provinceName,
        cityName: cityName,
        ...this.formLine,
        code: null
      }
      this.$set(this, 'formLine', JSON.parse(JSON.stringify(this.formLine)))
      console.log(this.formLine, 'formLine')
      // preSetInfo.infoList
      for (const i in this.materialData.stuAuditInfoVO.extendList) {
        const item = this.materialData.stuAuditInfoVO.extendList[i]
        if (item.type == 1) {
          for (let j = 0; j < this.preSetInfo.infoList.length; j++) {
            const child = this.preSetInfo.infoList[j]
            if (item.setId == child.id) {
              this.$set(this.preSetInfo.infoList[j], 'value', item.value)
              break
            }
          }
        } else {
          for (let j = 0; j < this.preSetInfo.dataList.length; j++) {
            const child = this.preSetInfo.dataList[j]
            if (item.setId == child.id) {
              item.values.forEach((eItem, eIdx) => {
                this.$set(this.preSetInfo.dataList[j].uploadSet[eIdx], 'imgUrl', eItem)
              })
              break
            }
          }
        }
      }
      const obj = JSON.parse(JSON.stringify({
        majorId: this.materialData.stuAuditInfoVO.majorId,
        majorName: this.materialData.stuAuditInfoVO.majorName
      }))
      this.getPreMajorList(this.formLine.admissionBatch, this.formLine.eduCategory, obj)
      console.log(obj, 'obj')
      console.log(this.preSetInfo.dataList, 'dataList')
      console.log(this.preSetInfo.infoList, 'infoList')
    },
    // 提交补充材料
    onSubmitMaterial () {
      // 材料校验是否齐全
      if (this.preSetInfo.dataList.length > 0) {
        for (const i in this.preSetInfo.dataList) {
          const obj = this.preSetInfo.dataList[i]
          if (obj.openMaterialWrite == 1) {
            if (obj.uploadSet.length > 0) {
              if (obj.requiredFlag == 1 && obj.show) {
                for (const j in obj.uploadSet) {
                  if (this.reUpload ? !obj.uploadSet[j].addValueNew : !obj.uploadSet[j].addValue) {
                    console.log(obj)
                    this.onTipMsg(obj.name + '还有材料未上传')
                    return
                  }
                }
              }
            }
          }
        }
      }
      const params = {
        // encoding: schoolName,
        cardId: this.materialData.stuAuditInfoVO.cardId,
        id: this.materialData.stuAuditInfoVO.id,
        stationId: this.materialData.stuAuditInfoVO.stationId || 29,
        extendList: []
      }
      if (this.preSetInfo.dataList.length > 0) {
        for (const i in this.preSetInfo.dataList) {
          const obj = this.preSetInfo.dataList[i]
          if (obj.openMaterialWrite == 1 && obj.show) {
            let str = 'addValue'
            if (this.reUpload) {
              str = 'addValueNew'
            }
            let value = obj.uploadSet[0][str]
            if (obj.name == '身份证照片') {
              if (obj.uploadSet[0][str] && obj.uploadSet[1][str]) {
                value = obj.uploadSet[0][str] + ',' + obj.uploadSet[1][str]
              } else if (obj.uploadSet[0][str]) {
                value = obj.uploadSet[0][str]
              } else if (obj.uploadSet[1][str]) {
                value = obj.uploadSet[1][str]
              } else {
                value = null
              }
            }
            const a = {
              name: obj.name,
              type: obj.type,
              setId: obj.id,
              addValue: value
            }
            if (value) {
              params.extendList.push(a)
            }
          }
        }
      }
      console.log(this.preSetInfo, 'preSetInfo')
      console.log(this.preSetInfo.dataList)
      this.$request.postPreAddMaterialUpload(params).then((res) => {
        if (res.data.code == 0) {
          if (this.reUpload) {
            this.onReUpload()
          }
          const code = sessionStorage.getItem('code') || this.$route.query.code || null
          this.materialList = res.data.data
          // this.materialData = res.data.data[0]
          if (res.data.data.length > 0) {
            for (const i in res.data.data) {
              const item = res.data.data[i]
              if (item.admissionBatch === this.materialData.admissionBatch) {
                this.materialData = item
                break
              }
            }
          }
        } else if (res.data.code == 1) {
          // this.$route.push('404')
        }
      }).catch((err) => { })
    }
  }
}
</script>
<style lang="scss" scoped>
.icon {
  width: 16px;
  height: 16px;
}

.yellow {
  color: #FF8400;
}

.red {
  color: #FF0000;
}

.blue {
  color: #1A7EE2;
}

.pointer {
  cursor: pointer;
}

.center {
  text-align: center;
}

::v-deep .van-tabs__nav {
  margin: 0;
  border-color: #FFFFFF !important;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;

  .van-tab {
    color: #3B3B3B !important;
    border-color: #FFFFFF !important;
    border-radius: 16px;
    padding: 10px 0px;

    &.van-tab--active {
      color: #FFFFFF !important;
    }
  }
}

.tipWall {
  width: 100vw;
  position: absolute;
  top: 0;
  overflow: scroll;
}

.uploadItem {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  line-height: 14px;
  margin-bottom: 14px;

  &:first-child {
    margin-top: 17px;
  }

  .uploadHead {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .red {
      color: #FF0202;
    }

    div {
      &.example {
        display: inline-flex;
        align-items: center;
        color: #1A7EE2;

        img {
          width: 14px;
          height: 14px;
        }
      }
    }
  }

  .uploadMain {
    .uploadImg {
      text-align: center;

      .text {
        height: 14px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
      }

      .uploadImgBox {
        background: rgba(239, 239, 239, 0.5);
        border-radius: 4px;
        margin: 10px 26px 6px;
        overflow: hidden;
        position: relative;

        img {
          width: 100%;
        }

        .van-image {
          border-radius: 4px;
          overflow: hidden;
        }

        .addImg {
          padding: 25px 38px;

          .add {
            width: 48px;
            height: 48px;
            position: absolute;
            left: 40%;
            top: 35%;
          }

          .bgImg {
            background-size: 100% 100% !important;
            min-height: 100px;
          }
        }
      }
    }
  }
}

.home .container {
  height: 100vh;
}

::v-deep .van-count-down {
  color: #999999;
}

.van-dropdown-item__content {
  .menuItem {
    color: #363840;
    margin: 24px;
    border-bottom: 2px solid #CCCCCC;
    padding-bottom: 23px;
    display: flex;
    align-items: baseline;

    &:last-child {
      border-bottom: none;
    }

    .tips {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #1A7EE2;
      margin-right: 6px;
    }

    .menuItemMain {
      .title {
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #363840;
        line-height: 18px;
      }

      .phone,
      .address {
        margin: 10px 0 6px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 14px;

        &.flex {
          display: flex;
        }

        .pTitle,
        .aTitle {
          height: 14px;
        }

        .pItem,
        .aItem {
          color: #363840;
          margin-top: 6px;
        }

        span {
          color: #363840;
        }
      }

      .address {
        .aTitle {}

        .aItem {}
      }
    }
  }
}

#fieldValue {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-width: 50px;
  border-radius: 6px;
  border: 1px solid #CCCCCC;
  padding: 5px 8px;

  span {
    font-size: 14px;
    line-height: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #646566;
    width: 100%;

    &.gray {
      color: #c8c9cc;
    }
  }

  img {
    width: 12px;
    height: 7px;
  }
}

.van-cell-group {
  margin: 0;
}

::v-deep .van-cell--required::before {
  left: 0;
}

.queryBatch {
  padding: 0 !important;

  ::v-deep .van-cell__title {
    width: auto;
  }

  ::v-deep #fieldValue span {
    font-size: 10px;
  }
}

.van-cell {
  padding: 13px 0;

  &::after {
    border-bottom-color: #FFFFFF;
  }

  ::v-deep .van-cell__right-icon {
    display: none;
  }
}

::v-deep .van-field__label {
  margin-left: 10px;
  margin-right: 10px;
  width: 84px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
}

.van-cell__value {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
}

[class*=van-hairline]::after {
  border-bottom-color: #FFFFFF;
}

::v-deep .van-dialog {
  .van-dialog__header {
    padding: 23px 0 10px;
    border-bottom: 1px solid #EFEFEF;
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 18px;
  }

  .van-dialog__content {
    font-size: 16px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #363840;
    line-height: 24px;
    word-break: break-all;
    // text-indent: 2em;
    max-height: 65vh;
    overflow-y: auto;

    .exampleItem {
      padding: 10px 19px 24px;

      div {
        img {
          max-width: 100% !important;
        }
      }
    }

    .exampleItem0 {
      padding: 10px 10px 16px;

      div {
        padding: 0 20px;
      }
    }

    .exampleItem1 {
      padding: 10px 27px 16px;

      div {
        text-indent: 0;
      }

      img {
        margin-top: 11px;
      }
    }

    img {
      width: 100%;
    }
  }

  .van-dialog__footer {
    border-top: 1px solid #EFEFEF;

    .van-button {
      height: 50px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 16px;
    }
  }
}

::v-deep .van-dropdown-menu__bar {
  height: auto;
  background: none;
  box-shadow: none;
}

::v-deep .van-dropdown-menu__title::after {
  display: none;
}

::v-deep .van-dropdown-menu__title {
  .van-ellipsis {
    font-size: 18px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #1A7EE2;
    line-height: 18px;

    .stationAlias {
      width: 50vw;
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: right;
    }
  }
}
.btn {
              display: flex;
              justify-content: space-between;
              padding-top: 10px;

              .btnItem {
                &.w100{
                  width: 100%;
                }
                &.blueBtn {
                  background: #1A7EE2;
                  color: #FFFFFF;
                }
                width: 140px;
                height: 34px;
                border-radius: 6px;
                font-size: 15px;
                font-family: PingFangSC-Regular, PingFang SC;
                font-weight: 400;
                line-height: 16px;
                padding: 0;

                &.next {
                  border: 1px solid #AAAAAA;
                  background: #FFFFFF;
                  color: #AAAAAA;
                }
              }

              &.center {
                justify-content: center;
              }

              .van-button {
                // width: 45%;
              }
            }
            .reEnroll {
            border-radius: 6px;
            height: 34px;
            &.reUpload {
              margin-bottom: 10px;
            }
          }
</style>
<style lang="scss" scoped>
.preRegistration {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #363840;
  line-height: 20px;
  overflow: scroll;
  height: inherit;
  position: relative;

  .preHeader {
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      height: 26px;
    }
  }

  .preMain {
    position: relative;
    background: #F9F9F9;
    padding-bottom: 16px;
    min-height: 80vh;

    .preMainBG {
      background: linear-gradient(180deg, #1A7EE2 0%, rgba(26, 126, 226, 0) 100%);
      opacity: 0.2;
      height: 300px;
      position: absolute;
      width: 100%;
      left: 0;
      top: 0px;
    }

    .preMainContent {
      // padding: 30px 24px;
      position: relative;

      .preMainHead {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #3B3B3B;
        line-height: 18px;
        padding: 30px 24px 0;

        &.showMenu {
          background: #FFFFFF;

          .preMainHeadL {
            width: 100%;

            ::v-deep .van-dropdown-menu__title {
              width: 100%;

              .van-ellipsis {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
              }
            }
          }
        }

        .center {
          height: 48px;
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #3B3B3B;
          line-height: 24px;
          width: 100%;
          text-align: center;
        }

        .preMainHeadL {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #3B3B3B;

          img {
            width: 12px;
            height: 7px;
            margin-left: 6px;
          }
        }
      }

      .preContentBox {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 24px 16px;
        margin: 0px 24px 16px;
        min-height: 50vh;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #363840;
        line-height: 14px;

        &.preContentBoxAuto {
          min-height: auto;
        }

        &.preContent2 {
          padding: 15px 0;

          .preHead2 {
            padding: 2px 15px 5px !important;
            display: flex;
            align-items: center;
          }

          .preHead3 {
            padding: 2px 15px 20px !important;
            border-bottom: 1px solid #EFEFEF;
            display: flex;
            align-items: center;
          }
        }

        .preBoxMain {
          padding: 0 15px;

        }

        &.preContentBox2 {
          z-index: 888;
          min-height: auto;
          position: relative;
        }

        .preHead {
          padding: 0 0 20px;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: MicrosoftYaHei;
          color: #666666;
          line-height: 18px;

          .icon {
            margin-right: 7px;
          }
        }

        .preContentItem {
          display: flex;
          justify-content: space-between;
          padding: 0 0 15px;

          >span,
          >div {
            word-break: break-all;
            max-width: 70%;
          }

          >span:first-child {
            max-width: 30%;
          }

          &:first-child {
            padding-top: 15px;
          }

          &.preContentItemColumn {
            flex-flow: column;

            >span,
            >div {
              word-break: break-all;
              max-width: 100%;
            }

            >span:first-child {
              max-width: 100%;
            }

            .preInfoLable {
              margin-bottom: 10px;
            }

            >div .infoImg {
              margin-bottom: 10px;
              border-radius: 10px;
              overflow: hidden;
            }
          }

          &.preContentItemCenter {
            align-items: center;
          }

          .preInfoLable {
            display: flex;
            flex-flow: column;
          }

          .tips {
            margin-top: 5px;
            font-size: 10px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #FF0000;
            line-height: 12px;
          }
          .textR{
            text-align:right;
          }
          .infoBtn {
            color: #1A7EE2;
            padding: 0.133333rem 0.266667rem;
            background: rgba(26, 126, 226, 0.1);
            border-radius: 4px;
            border: 1px solid #1A7EE2;
          }

          .studyAddress {
            text-align: right;

            .studyCoyp {
              margin-top: 5px;

              .copy {
                color: #1A7EE2;
                padding: 0.133333rem 0.266667rem;
                background: rgba(26, 126, 226, 0.1);
                border-radius: 4px;
                border: 1px solid #1A7EE2;
              }
            }
          }
        }

        .preContentTime {
          font-size: 12px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #363840;
          line-height: 14px;
          padding-bottom: 20px;
          border-bottom: 1px solid #eee;
          margin-bottom: 20px;

          &.noline {
            border-bottom: none !important;
            margin-bottom: 0px;
          }
        }

        .preContentHead {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 10px;

          &.column {
            flex-flow: column;
          }

          .title {
            font-size: 18px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #363840;
            margin-bottom: 18px;
          }

          .headMain {
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #666666;
            line-height: 18px;
            display: flex;
            align-items: center;

            .icon {
              margin-right: 10px;
            }
          }

          .preHeadItem {
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #D1D1D1;
            line-height: 14px;
            display: flex;
            flex-flow: column;
            align-items: center;

            &.onStep {
              color: #1A7EE2;

              .num {
                background: #1A7EE2;
              }
            }

            .num {
              width: 28px;
              height: 28px;
              background: #D1D1D1;
              border-radius: 50%;
              text-align: center;
              line-height: 28px;
              font-size: 16px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #FFFFFF;
              margin-bottom: 10px;
            }

            span {}
          }

          .preHeadLine {
            width: 70px;
            height: 1px;
            background: #D1D1D1;
            margin-bottom: 24px;
          }
        }

        .preContent {
          .van-form {
            .van-cell-group {
              .van-field {
                .bottom24 {
                  margin-bottom: 24px;
                }

                .bottom24-0,
                .bottom24-1 {
                  margin-bottom: 24px;
                }

                &.line {
                  ::v-deep .van-field__value {
                    border-bottom: 1px solid #CCCCCC;
                    padding-bottom: 5px
                  }
                }

                &.phone {
                  ::v-deep .van-field__button {
                    line-height: initial;
                    display: flex !important;

                    .sentCode {
                      border: none;
                      font-size: 14px;
                      font-family: PingFangSC-Regular, PingFang SC;
                      font-weight: 400;
                      color: #1A7EE2;
                      height: auto;
                      padding: 0;
                      margin: 0;
                    }
                  }
                }
              }

            }

          }
        }
      }

      .preContentFoot {
        background: #FFFFFF;
        border-radius: 10px;
        padding: 20px 16px;
        margin: 0px 24px 0px;

        .preConFootItem {
          display: flex;
          margin-bottom: 12px;

          .icon {
            width: 90px;
            // justify-content: space-between;
            display: flex;
            margin-right: 10px;
            color: #999999;

            .iconW {
              width: 25px;
            }

            img {
              height: 16px;
              width: auto;
              margin-right: 8px;
            }

            span {}
          }

          .text {
            width: calc(100% - 100px)
          }
        }
      }
    }

  }

  .preFooter {
    padding: 24px;
    background: #27282C;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 16px;

    .preFootH {
      height: 26px;
      color: #1A7EE2;
      margin-bottom: 16px;
      display: flex;
      align-items: center;

      img {
        height: 100%;
        margin-right: 7px;
      }

      span {}
    }

    .preFootMain {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .fLeft {
        flex: 1.5;

        div {
          margin-bottom: 14px;
          display: flex;
          align-items: center;

          &:last-child {
            margin-bottom: 0px;
          }

          img {
            height: 20px;
            width: 18px;
            margin-right: 10px;
          }

          a {
            color: #999;
          }
        }
      }

      .fRight {
        width: 100px;
        flex: 0.9;
        display: flex;
        flex-flow: column;
        align-items: center;
        text-align: center;

        img {
          width: 80px;
          height: 80px;
        }

        div {
          padding: 0px 6px;
          margin-top: 6px;
        }
      }
    }
  }
}</style>
